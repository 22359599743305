
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  /* overflow-x: hidden; */
  background-color: #011627;
}

*, ::before, ::after {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0px;
}

h1, h2, h3, h4, h5{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
}

li,p , a{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}